<template>
  <div class="page-web page_regi">
    <RegiHead :MyLocal="location" @newLocal="location = $event" />
    <div class="regi-bd">
      <div class="regi_name">{{ $t('account3') }}</div>
      <el-form ref="form2" :rules="rules2" class="regi-from" :model="form2">
        <el-form-item prop="email">
          <div class="regi_group">
            <div class="regi_gr_t">{{ $t('email2') }}</div>
            <div class="regi_gr_b">
              <el-input v-model="form2.email" placeholder=""></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="captcha">
          <div class="regi_group">
            <div class="regi_gr_t">{{ $t('code') }}</div>
            <div class="regi_gr_b flex flex-ac">
              <el-input v-model="form2.captcha" placeholder=""></el-input>
              <el-button v-if="disabled2" class="btn btn_yzm" :plain="true" @click="sendEmail">
                {{ $t('code2') }}
              </el-button>
              <el-button v-else class="btn btn_yzm" :plain="true" :disabled="!disabled2">
                {{ count2 }}
              </el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="password">
          <div class="regi_group">
            <div class="regi_gr_t">{{ $t('password') }}</div>
            <div class="regi_gr_b">
              <el-input v-model="form2.password" placeholder="" :type="pass2 ? 'password' : 'text'"> </el-input>
              <div class="regi_eye" @click="eye2">
                <i class="iconfont icon-eye-close" v-if="Eye2"></i>
                <i class="iconfont icon-eye" v-else></i>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="checkpassword2">
          <div class="regi_group">
            <div class="regi_gr_t">{{ $t('password4') }}</div>
            <div class="regi_gr_b">
              <el-input v-model="form2.checkpassword2" placeholder="" :type="pass3 ? 'password' : 'text'"> </el-input>
              <div class="regi_eye" @click="eye3">
                <i class="iconfont icon-eye-close" v-if="Eye3"></i>
                <i class="iconfont icon-eye" v-else></i>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="regi_group">
            <div class="regi_gr_t">{{ $t('invite') }}</div>
            <div class="regi_gr_b">
              <el-input v-model="form2.invite" placeholder="" :disabled="invites"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="check">
          <div class="regi_group regi_group_check flex">
            <el-checkbox-group v-model="form2.check">
              <el-checkbox name="type">{{ $t('agree') }} </el-checkbox>
            </el-checkbox-group>
            <router-link to="/register" >
             <span @click="openpdf">《{{ $t('agreement') }}》</span>
            
              </router-link >
              
          </div>
         
        </el-form-item>
        <el-button class="btn" :plain="true" @click="submitForm2()">{{ $t('register') }} </el-button>
      </el-form>

      
      <div class="regi_tips">Bnbncoin ©2024</div>
      <el-dialog
        :visible.sync="dialogVisible"
        :show-close="false"
        custom-class="custom-dialog2"
        :close-on-click-modal="false"
      >
      </el-dialog>
      <vueshowpdf @closepdf="closepdf"
                v-model="isshowpdf"
                :pdfurl="src"
                @pdferr="pdferr"
                :maxscale='4'
                :minscale='0.6'
                :scale='1.0'></vueshowpdf>  
    </div>
  
  </div>
  
</template>
<script>
// import dragVerify from '@/components/dragVerify'
import vueshowpdf from 'vueshowpdf'
import RegiHead from '@/components/RegiHead'
import {getcaptcha, addUser} from '@/api/virtual/register'
export default {
  components: {
    RegiHead,
    vueshowpdf
    // dragVerify,
  },
  data() {
    const validateUser = (rule, value, callback) => {
      var reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
      reg = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/
      if (value == '') {
        callback(new Error(this.$t('mailtip')))
      } else if (!reg.test(this.form2.email)) {
        callback(new Error(this.$t('emailerr2')))
      } else {
        callback()
      }
    }
    var validatePass3 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('logErr2')))
      } else {
        if (this.form2.checkpassword2 !== '') {
          this.$refs.form2.validateField('checkpassword2')
        }
        callback()
      }
    }
    var validatePass4 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('confirmpwdtip')))
      } else if (value !== this.form2.password) {
        callback(new Error(this.$t('confirmpwderr')))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      invites:false,
      src: '/static/user_en.pdf',
      isshowpdf: false,
      invite:"",
      show: false,
      activeName: 'second',
      Eye: true,
      Eye1: true,
      Eye2: true,
      Eye3: true,
      pass: true,
      pass1: true,
      pass2: true,
      pass3: true,
      country: [],
      countryImageUrl: '',
      form2: {
        // 国家
        email:'',
        captcha: '',
        password: '',
        checkpassword2: '',
        invite: '',
        check: [],
      },
      rules2: {
        email: [
          {
            required: true,
            validator: validateUser,
            trigger: 'blur',
          },
          {
            type: 'email',
            message: this.$t('emailerr2'),
            trigger: ['blur', 'change'],
          },
        ],
        captcha: [
          {
            required: true,
            message: this.$t('regErr'),
          },
        ],
        password: [
          {
            validator: validatePass3,
            trigger: 'blur',
          },
          {
            type: 'string',
            min: 6,
            message: this.$t('logErr3'),
            trigger: 'blur',
          },
        ],
        checkpassword2: [
          {
            validator: validatePass4,
            trigger: 'blur',
          },
        ],
        check: [
          {
            type: 'array',
            required: true,
            message: this.$t('agreementtip'),
            trigger: 'change',
          },
        ],
      },
      location: 'en_US',
      dialogVisible: false,
      waitTime: 60,
      count: '60s',
      disabled: true,
      waitTime2: 60,
      count2: '60s',
      disabled2: true,
      screenWidth: null,
    }
  },
  created() {
    this.invite = this.$route.query.invite
    if (this.invite != undefined) {
      this.form2.invite = this.invite
      this.invites=true
    }
  },
  watch: {},
  mounted() {
    
  },
  methods: {
    closepdf () {
      this.isshowpdf = false
    },
    pdferr (errurl) {
      console.log(errurl)
    },
    openpdf () {
      const lang = localStorage.getItem('lang')
      console.log(lang);
      if(lang=="zh_CN")
          {
            this.src='/static/user_zh.pdf';
          }
        else if(lang=="en_US")
        {
          this.src='/static/user_en.pdf';
        }
        else if(lang=="zh_HK")
        {
          this.src='/static/user_hk.pdf';
        }
        else if(lang=="ja_JP")
        {
          this.src='/static/user_ja.pdf';
        }
        else if(lang=="ko_KR")
        {
          this.src='/static/user_ko.pdf';
        }
        else if(lang=="de_DE")
        {
          this.src='/static/user_de.pdf';
        }
        else if(lang=="fr_FR")
        {
          this.src='/static/user_fr.pdf';
        }
        else if(lang=="it_IT")
        {
          this.src='/static/user_it.pdf';
        }
        else if(lang=="es_ES")
        {
          this.src='/static/user_es.pdf';
        }
         else if(lang=="ar_AR")
        {
          this.src='/static/user_ar.pdf';
        }
         else if(lang=="pt_PT")
        {
          this.src='/static/user_pt.pdf';
        }
      this.isshowpdf = true
    },
    // onCloseCaptcha(data) {
    //   this.dialogVisible = data
    // },
    countryItem(url) {
      this.countryImageUrl = url
    },
    sendEmail() {
      var reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
      reg = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/
      if (this.form2.email == '') {
        this.$message.error(this.$t('mailtip'))
      } else if (!reg.test(this.form2.email)) {
        this.$message.error(this.$t('emailerr2'))
      } else {
        const storedTime = localStorage.getItem('lastClickGetDataTime') 
        const currentTime = Date.now() // 时间戳(秒级)
        if (!storedTime || (currentTime - storedTime) / 1000 / 60 >= 1) {
        getcaptcha(this.form2.email).then(response => {
          if(response.code==200)
          { this.$message({
          message: response.msg,
          type: 'success'
          });
          }
          else{
            this.$message.error(response.msg)
          }
      });
      localStorage.setItem('lastClickGetDataTime', currentTime) 
      }else {  
        // 距离上次点击时间小于10分钟，不做任何操作或给出提示  
        this.$message({  
          message: this.$t('captcha'),  
          type: 'warning',
        })
      }    
      }
    },
    submitForm2() {
    if (this.loading) {
            return false;
          }
          this.loading = true; 
      if(this.form2.captcha==undefined)
      {
        this.$message.error(this.$t('verificationcode'));
      }
      else{
          addUser(this.form2).then(response=>{
          console.log(response);
          // 调用接口,接口完成后，把loading值改为false
          this.loading = false;
        if(response.code==200)
        {
          this.$message({
          message: response.msg,
          type: 'success'
          });
          this.$router.push('/login')
        }
        else{
          this.$message.error(response.msg);
        }
      })
      }
    },

    handleClick(tab, event) {
      console.log(tab, event)
    },
    eye() {
      this.Eye = !this.Eye
      this.pass = !this.pass
    },
    eye1() {
      this.Eye1 = !this.Eye1
      this.pass1 = !this.pass1
    },
    eye2() {
      this.Eye2 = !this.Eye2
      this.pass2 = !this.pass2
    },
    eye3() {
      this.Eye3 = !this.Eye3
      this.pass3 = !this.pass3
    },
  },
}
</script>
